
body {
  margin: 0px;
  background-color: #FBFBFC;
  font-family: 'Anuphan';
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
}


@font-face {
  font-family: 'Anuphan';
  font-weight: 200;
  src: local('Anuphan'), url(./assets/fonts/Anuphan-Thin.otf) format('opentype');
}

@font-face {
  font-family: 'Anuphan';
  font-weight: 300;
  src: local('Anuphan'), url(./assets/fonts/Anuphan-Light.otf) format('opentype');
}

@font-face {
  font-family: 'Anuphan';
  src: local('Anuphan'), url(./assets/fonts/Anuphan-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Anuphan';
  font-weight: 400;
  src: local('Anuphan'), url(./assets/fonts/Anuphan-Regular.otf) format('opentype');
}


@font-face {
  font-family: 'Anuphan';
  font-weight: 500;
  src: local('Anuphan'), url(./assets/fonts/Anuphan-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Anuphan';
  font-weight: 600;
  src: local('Anuphan'), url(./assets/fonts/Anuphan-SemiBold.otf) format('opentype');
}

@font-face {
  font-family: 'Anuphan';
  font-weight: 700;
  src: local('Anuphan'), url(./assets/fonts/Anuphan-Bold.otf) format('opentype');
}