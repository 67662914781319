.labelWrapper {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100px;
}

.labelFilteredWrapper {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100px;

  font-weight: 700;
}

.labelWrapper:hover {
  background-color: #CCCCCC;
  border-radius: 8px;
}

.labelFilteredWrapper:hover {
  background-color: #CCCCCC;
  border-radius: 8px;
}

:global(.anticon.anticon-down).arrow {
  font-size: 14px;
  font-weight: 200;
}

:global(.anticon.anticon-up).arrow {
  font-size: 14px;
  font-weight: 200;
}

.labelFilteredWrapper :global(.anticon.anticon-down).arrow {
  font-size: 14px;
  font-weight: 700;
}

.labelFilteredWrapper :global(.anticon.anticon-up).arrow {
  font-size: 14px;
  font-weight: 700;
}