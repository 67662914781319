.divList {
    /* height: 100vh;
    background-color: #1665C0;
    width: 60px; 
  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; */


    /* border: 1.5px solid #706d6d; */
    border-radius:  7px;
     /* display: "inline-block", */
    padding: 25px;
    width: 97% ;
    margin-left: 1em;
    margin-top: 2em;
    background-color : #FFFFFF;
  
    /* color: #3d87db */
  }

  .divListDetail {
  

      /* border: "1.5px solid #706d6d", */
    border-radius: 7px;
    padding: 20px;
    width: 100%;
    margin-top: 1em;
    /* margin-left: 1em; */
    background-color: #fbfcfd;
  }


  .divOwnerUser {
     /* border: "1.5px solid #706d6d", */
    border-radius: 15px;
    padding: 5px;
    width: 97%;
    margin-left: 1em;
    margin-top: 1em;
    background: #E6E6FA;
  }

  .imgOwnerUser{
    /* border: "1.5px solid #706d6d",
    borderRadius: "15px", */
    padding: 2px;
    width: 100%;
    /* margin: auto; */
    /* Justify-Content: center;  */
     /* margin-left: 1em; */
     margin-top: 1.5em; 
    background: #E6E6FA;
  }

  .button1 {
    /* box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19); */
    border-radius: 10px;
    background-color:#6633FF; /* Green */
    width: 250px;
    color: white;
  }

  .divAdd {
    /* border: 1.5px solid #706d6d; */
    border-radius: 15px;
    padding: 20px;
    width: 97%;
    margin-left: 1em;
    margin-top: 2em;
    background : #FFFFFF;
  }

  .divAdd2 {
    /* border: 1.5px solid #706d6d; */
    border-radius: 15px;
    padding: 5px;
    width: 97%;
    margin-top: 1em; 
    margin-top: 1em;
    background : #E6E6FA;
  }

  .divAddImg{
    /* border: "1.5px solid #706d6d", */
    border-radius: 15px;
     padding: 10px;
     /* width: "97%", */
     margin-top: 1em;
     background : #E6E6FA;
  }

  .divAddImg2{
  
    border-radius: 15px;
    margin-top: 1em;
    background : #E6E6FA;
  }

  .inputnumberRight {
    text-align: right,
  }