.sideNav {
  height: 100vh;
  /* background-color: #1665C0; */
  display: flex;
  /* width: 300px; */

  position: fixed;
  top: 0;
  bottom: 0;

}

.sideNavMenu {
  height: 100vh;
  background-color: #fff;
  width: 225px;
}

.sideNavMenu :global(.ant-menu) {
    background-color: #fff;
}

.sideNavMenu :global(.ant-menu-item:hover) , .sideNavMenu :global(.ant-menu-submenu-title:hover) {
  background-color: rgba(38,141,221,.1);
  /* background-color: #997733; */
}

/* .sideNavMenu .ant-menu-item:hover,
.sideNavMenu .ant-menu-submenu-title:hover {
  background-color: none;
  color: blue;
} */

.notificationBar {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
}

.notificationContainer {
  display: flex;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  align-items: center;
  margin-right: 20px;
}

.notificationItem {
  padding: 3px 10px;
  margin: 0px 2px;

  cursor: pointer;
  display: flex;
  align-items: center;
}

.notificationItem:hover {
  background-color: rgb(220, 226, 230);
  border-radius: 8px;
}