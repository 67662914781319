.sideNavModule {
  height: 100vh;
  /* background-color: #FDC307; */
  background-color: #FFF;
  width: 60px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  border: 1px solid #0000001a;
  box-shadow: 0 1px 1px 0 #0000001a, 0 1px 2px 1px #0000001a;
}

.sideNavModuleMenuWrapper {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sideNavModuleFooterWrapper {
  margin-bottom: 20px;
}

.sideNavModuleMenuIcon {
  padding: 10px 10px 3px 10px;

  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 8px;

  /* box-shadow: 0 0 0 2px rgb(227, 226, 226); */

  /* background-color: rgb(227, 226, 226); */
  background-color: #FFF;
  cursor: pointer;

}

.sideNavModuleMenuIcon > svg{
  /* stroke: #012085; */
  stroke: #BA0000;
  stroke-width: 3.0;
}

.sideNavModuleMenuIcon:hover {
  background-color: #FDC307;
  border: 1.5px solid #BA0000;
  /* box-shadow: 1px 1px 30px #888; */
}

.sideNavModuleMenuIcon:hover > svg {
  /* stroke: #012085; */
  stroke: #BA0000;
  /* stroke-width: 2.0; */
}

.sideNavModuleIcon {
  padding: 8px 10px 3px 10px;

  margin-bottom: 10px;
  border-radius: 8px;
}

.sideNavModuleIcon > svg {
  stroke-width: 1.8;
  stroke: #BA0000;
}

.sideNavModuleIcon:hover {
  /* background-color: #3d87db; */
  background-color: #1B25F5;
  border: #1B25F5;
  /* background-color: #D0021B;
  border: #D0021B; */
  cursor: pointer;

}

.sideNavModuleIcon:hover > svg {
  /* stroke: #012085; */
  stroke: #FFF;
}

.sideNavModuleIconActive {
  padding: 8px 10px 3px 10px;

  margin-bottom: 10px;
  border-radius: 8px;

  /* background-color: #3d87db; */
  background-color: #1B25F5;
  border: #1B25F5;

  cursor: pointer;
}

.sideNavModuleIconActive > svg {
  /* stroke: #012085; */
  stroke: #FFF;
  stroke-width: 1.8;
}


/* .sideNavModule span {
  color: #fff;
  cursor: pointer;


} */