
.container{
    flex: 1;
    flex-direction: column;
    background-color: #DDD;
    /* width: 100%;
    height: 100%; */
}
.header{
    flex: 1;
    /* width: 100%;
    height: 100px; */
    flex-direction: row;
    border: 2px solid black;
    align-items: center;
    /* background-color: blue; */
}
.headerName{
    width: 50%;
    align-items: center;
    margin-left: 20px;
}
.headerButton{
    width: 50%;
    align-items: center;
    justify-content: flex-end;
    margin-right: 20px;
}
.contentBody{
    flex: 10;
    /* width: 100%;
    height: 500px; */
    flex-direction: column;
    display: flex;
    /* background-color: cadetblue; */
}
.rowBody{
    flex: 1;
    flex-direction: row;
    display: flex;
    /* background-color: aqua; */
}
.columnBody{
    flex: 1;
    flex-direction: column;
    display: flex;
    /* background-color: #FFF; */
}

.radiusBody{
    border-radius: 20px;
    background-color: #FFF;
}

.canClickItem{
    cursor: pointer;
}