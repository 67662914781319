.labelright {
  color: #0a0401;
  /* font-weight: bold; */
  display: block;
  width: 150px;
  /* float: left; */
  text-align: right;
  padding-right: 5px;
  padding-top: 5px;
}

.inputtext{
  width: 200px;
}

.inputnum{
  width: 150px;
  text-align: right;
}

.select{
 width: 200px;
 
}